.nurse-container {
    background-color: rgba(39, 40, 41, 0.9);
    display: flex;
    margin: 10px 0px 10px 0px;
    padding: 10px;
    width: calc(100% - 20px);
    overflow-x: auto;
}

.nurse-container .icon {
    width: 42px;
}

.nurse-container .icon .nurse-icon {
    background-image: url("../../assets/svgs/nurse_i_guess.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    margin-left: 8px;
}

.nurse-entry{
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    margin-left: 2px;
    margin-right: 2px;
    padding: 2px 5px 2px 5px;
}

.nurse-entry:hover{
    background-color: #3B3D3F;
}

.nurse-entry .name{
    font-size: 13px;
    white-space: nowrap
}

.nurse-entry .active{
    background-color:#DE0A0A;
    border-radius: 8px;
    height: 8px;
    margin-right: 8px;
    width: 8px;
}