input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: rgba(39, 40, 41, 0.9) !important;
  color: #fff !important;
}

.sign-in {
  margin: 0;
  min-height: 100vh;
  width: 100%;
}

.sign-in .inner {
  background-color: #181818;
  left: 50%;
  max-width: 250px;
  padding: 50px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.sign-in .inner .container {
  background-color: #181818;
}

.sign-in .inner .logo {
  background-image: url("../../assets/images/xs_alert_logo_2.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 65px;
  margin: 0 auto 25px auto;
  width: 133px;
}

.sign-in .inner form {
  width: 100%;
}

.sign-in .inner form .row {
  margin: 15px 0 0 0;
  width: 100%;
}

.sign-in .inner form input {
  border: none;
  border-radius: 3px;
  color: #181818;
  font-size: 15px;
  padding: 12px;
  width: calc(100% - 24px);
}

.sign-in .inner form input:focus {
  opacity: 1;
}

.sign-in .inner form .row.submit {
  margin: 20px auto 0 auto;
  text-align: center;
}

.sign-in .inner form .row.submit div {
  margin: 0 auto;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}

.sign-in p.small {
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  margin: 5px 0;
  text-align: right;
}

.sign-in p.small:hover {
  color: #0ade0a;
  text-decoration: underline;
}

.sign-in #login-error {
  color: #DE0A0A;
  font-size: 14px;
  text-align: center;
}

/* SelectOrganization.js */

.org-page{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  color: white;
}

.org-page h1, h2, h3, h4, h5, h6 {
  margin: 10px;
  padding: 0;
}

.org-page .title {
  text-align: center; 
  font-size: 20px;
}

.org-page .container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  background-color: #181818;
  min-height: 60%;
  min-width: 80%;
}

.org-page .orgs-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 5%;
  justify-content: center;
  align-items: center;
}

.org-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.org-page .orgs-container .org {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5%;
  margin: 15px;
  background-color: grey;
  min-height: 100px;
  max-height: 150px;
  min-width: 100px;
  max-width: 150px;
  overflow: hidden;
}

.org-page .orgs-container .org:hover {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05); 
}

.org-default-img {
  object-fit: cover;
  min-height: 80%;
  width: 100%;
}