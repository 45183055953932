.shoutbox{
  overflow-y: auto;
}

.shoutbox .shout-message{
  background-color: #282828;
  cursor: default;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 5px 10px 5px 10px;
}

.shoutbox .shout-message .timestamp{
  font-weight: bold;
  margin-right: 10px;
}
.shoutbox .shout-message .room,
.shoutbox .shout-message .user{
  cursor: pointer;
  text-decoration: underline;
}

.shoutbox .shout-message .room:hover ,
.shoutbox .shout-message .user:hover {
  font-weight: bold;
}

.removedDrawer {
  width: 0 !important;
}

.fade-in {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}