.language #rfs-btn {
    color: inherit;
    border-color: transparent;
    font-family: inherit,
}

.language ul {
    background-color: rgb(39, 40, 41);
    border-color: rgb(76, 76, 76) !important;
}

.language li:hover{
    background-color: rgb(76, 76, 76) !important;
}