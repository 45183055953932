.middle{
    width: 100%;
}

.middle .middle-top{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.middle .middle-top .room-info{
    margin-right: 45px;
    width: calc(100% - 175px);
}

.middle .middle-top .room-stats{
    height: 150px;
    opacity: 0.8;
    width: 150px;
}

.room-info .room-header{
    font-size: 30px;
    font-weight: bold;
}

.room-info .room-header .room-ph{
    border-radius: 30px;
    height: 30px;
    opacity: 0.7;
    width: 200px;
}

.room-info .client-info{
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.room-info .client-info .icon{
    background-image: url(../../assets/svgs/person.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    margin: 0 5px 0 0;
    width: 20px;
}


.room-info .building-info{
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.room-info .building-info .icon{
    background-image: url(../../assets/svgs/building.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    margin: 0 5px 0 0;
    width: 20px;
}

.middle .nextxs-list {
    margin-top: 25px;
}

.middle .nextxs-list .list-items{
    height: 50vh;
    overflow-y: scroll;
    flex:1;
}

.middle .nextxs-list .list-item {
    cursor: default;
    display: flex;
    margin: 1px 0 1px 0;
    padding: 5px 10px 5px 10px;
}

.middle .nextxs-list .list-item .info.type-icon{
    flex-grow: 0;
    justify-content: flex-start;
    margin-right: 0px !important;
    min-width: 30px;
    width: 30px;
}
.middle .nextxs-list .legend-bar .legend-item.type-icon {
    align-items: center;
    flex-grow: 0;
    margin-right: 0px !important;
    min-width: 30px;
    width: 30px;
}

.name{
    font-size: 16px;
}

.name-ph{
    border-radius: 16px;
    height: 16px;
    opacity: 0.7;
    width: 150px;
}

.leave {
    background-image: url(../../assets/svgs/door-white.svg);
}

.soeb {
    background-image: url(../../assets/images/edge-of-bed.png);
    filter: invert(1);
}

.man-down {
    background-image: url(../../assets/images/man-losing-hat.png);
    filter: invert(1);
}

.out-of-bed {
    background-image: url(../../assets/svgs/bed-24px.svg);
}

.shimmer {
    animation: shimmer 1s infinite linear;
    background: linear-gradient(to right, #0a0a0a 4%, #1f1f1f 25%, #0a0a0a 36%);
    background-size: 1000px 100%;
}

@keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  