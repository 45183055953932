body,
html {
  font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  font-size: 1em;
  margin: 0;
  padding: 0;
}

.page .inner {
  text-align: left;
}

a {
  color: #fff;
  font-family: "Open Sans";
  text-decoration: none;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

label,
p,
span {
  font-size: 15px;
  line-height: 1.618em;
}

button {
  cursor: pointer;
}

button,
input {
  outline: none;
}

/* input[type="password"],
input[type="text"] {
  background-color: #fff;
  border: none;
  border-radius: 3px;
  color: #181818;
  display: block;
  font-size: 14px;
  padding: 10px;
  width: calc(100% - 20px);
} */

input[type="date"] {
  background-color: #fff;
  border-radius: 3px;
  border: none;
  color: #181818;
  float: right;
  font-family: "Open Sans";
  height: 33px;
  margin: 1px 0 0 0;
  padding: 0 8px 0 10px;
  position: relative;
  width: calc(100% - 4px);
}

input[type="time"] {
  border-radius: 3px;
  border: 1px solid #181818;
  background-color: #fff;
  color: #181818;
  font-family: "Open Sans";
  position: relative;
  float: right;
  width: calc(100% - 4px);
  height: 33px;
}

.checkbox-container {
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin: 0 0 4px 0;
  padding: 0 0 0 28px;
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.checkbox-container input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkbox-container .checkmark {
  background-color: #eee;
  height: 18px;
  left: 0;
  margin: 2px 0 0 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #0967de;
}

.checkbox-container .checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  height: 7px;
  left: 6px;
  top: 3px;
  width: 4px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: block;
  height: 33px;
  padding: 6px;
  margin: 1px 0 0 0;
  width: 100%;
}

input:focus,
select:focus {
  outline: none;
}

.button {
  background-color: #0ade0a;
  border-radius: 20px;
  border: 1px solid #0ade0a;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: max-content;
  margin: auto 10px auto 0;
  padding: 9px 15px 8px 15px;
  text-align: center;
  text-transform: uppercase;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: max-content;
}

.button:focus {
  outline: none;
  border: none;
}

.button:hover {
  background-color: #0ad60a;
  border: 1px solid #0ad60a;
  transition: 0.2s;
}

.button.cancel {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
}

.button.cancel:hover {
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.button.delete {
  background-color: #ff4242;
  border: 1px solid #ff4242;
}

.button.delete:hover {
  background-color: #ff1f1f;
  border: 1px solid #ff1f1f;
}

.pop-up.fadeIn {
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.pop-up.fadeOut {
  height: 0%;
  left: 50%;
  position: fixed;
  top: 50%;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  width: 0%;
  z-index: 4;
}

.pop-up .inner {
  background-color: #1f2021;
  color: #fff;
  cursor: default;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  height: auto;
  left: 50%;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 50%;
  transform: translate(-50%, -50%);
  /* width: 400px !important; */
}

.pop-up .inner .top {
  background-color: #181818;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 8px 8px 8px;
}

.pop-up .inner .top .close {
  background-image: url(./assets/svgs/cross-light.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 16px;
  opacity: 0.75;
  width: 16px;
}

.pop-up .inner .top .close:hover {
  opacity: 1;
  transition: 0.1s;
}

.pop-up .inner .middle {
  padding: 13px 26px 26px 26px;
}

.pop-up .inner .middle h2 {
  margin-top: 0;
}

.pop-up .inner .middle p {
  color: #fff;
  margin-bottom: 0;
}

.pop-up .inner .middle .checkboxes {
  margin: 13px 0 0 0;
}

.pop-up .inner .bottom {
  background-color: #272829;
  padding: 13px 26px;
}

.pop-up .inner .bottom .buttons {
  margin: 0 0 0 auto;
  width: max-content;
}

.pop-up .inner .bottom .buttons .button.add,
.pop-up .inner .bottom .buttons .button.delete {
  margin-right: 0;
}

/* Nextxs lists */

.left-side {
  padding: 0 10px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.left-side .inner {
  width: 100%;
  overflow: hidden !important;
}

.left-side .inner .nurse-container {
  width: 100%;
}

.right-side {
  height: 100%;
  width: 30vw;
  min-width: 200px;
  overflow: hidden;
  position: relative;
}


.right-side .inside {
  height: calc(100% - 2px);
  overflow-y: auto;
  position: relative;
}

.nextxs-list .list-item {
  background-color: rgba(39, 40, 41, 0.9);
  cursor: pointer;
  display: flex;
  margin: 0 0 1px 0;
  padding: 10px;
  width: calc(100% - 20px);
}

.nextxs-list .list-item:hover {
  background-color: rgba(39, 40, 41, 0.85);
}

.nextxs-list .list-item.group-list-item:hover {
  background-color: rgba(39, 40, 41, 1);
}

.nextxs-list .list-item.user-list-item:hover {
  background-color: rgba(39, 40, 41, 0.85);
}

.nextxs-list .list-item:hover .username {
  color: #0ade0a;
}

.nextxs-list .list-item .photo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  margin: auto 0 auto 0;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
}

.nextxs-list .list-item .photo.group {
  background-color: #121212;
  background-image: url("./assets/svgs/groups.svg");
}

.nextxs-list .list-item .photo.initials {
  align-items: center;
  background-color: #181818;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 100px;
  justify-content: center;
  transition: 0.2s;
}

.nextxs-list .list-item .info {
  display: flex;
  min-width: 140px;
  flex-grow: 140;
  width: 140px;
  max-width: unset;
  padding: 0px 5px;
}

.nextxs-list .list-item .info.time{
  width: 60px;
  min-width: 60px;
  flex-grow: 0;
}

.nextxs-list .list-item .info.prio,
.nextxs-list .list-item .info.status{
  width: 30px;
  min-width: 30px;
  flex-grow: 0;
}

.nextxs-list .list-item .info.user-icon {
  width: 50px;
  min-width: 50px;
  flex-grow: 0;
}

.nextxs-list .list-item .info .name {
  display: flex;
  align-items: center;
  color: #fff;
  margin: auto 0;
  font-size: 0.9em;
}

.nextxs-list .list-item .info .fat.name {
  font-weight: 600;
}

.nextxs-list .list-item .info .mail {
  color: #fff;
  margin: auto 0 auto 26px;
  padding: 0 0 0 26px;
  font-size: 14px;
  width: 100%;
}

.nextxs-list .legend-bar .legend-item.client-img,
.nextxs-list .legend-bar .legend-item.nurse-img,
.nextxs-list .list-item .info.client-img,
.nextxs-list .list-item .info.nurse-img{
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  align-self: center;
  margin-right: 10px;
}

.nextxs-list .list-item .info.client-img .initials,
.nextxs-list .list-item .info.nurse-img .initials,
.nextxs-list .list-item .info.client-img .photo,
.nextxs-list .list-item .info.nurse-img .photo{
  background-color: #181818;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  min-height: 40px;
  min-width: 40px;
  max-width: 40px;
  margin-right: 10px;
  text-align: center;
}

.nextxs-list .legend-bar .legend-item.room-name,
.nextxs-list .list-item .info.room-name{
  min-width: 120px;
  max-width: 120px;
  padding: 0px 10px 0px 10px;
}

.nextxs-list .legend-bar {
  display: flex;
  margin: 0 0 10px 0;
  padding: 0 10px;
  width: fit-content;
}

.nextxs-list .legend-bar .legend-item {
  font-size: 0.7em;
  opacity: 0.75;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  min-width: 140px;
  flex-grow: 140;
  width: 140px;
  max-width: unset;
  padding-right: unset;
}

.nextxs-list .legend-bar .legend-item.time{
  width: 60px;
  min-width: 60px;
  flex-grow: 0;
}

.nextxs-list .legend-bar .legend-item.prio,
.nextxs-list .legend-bar .legend-item.status{
  width: 30px;
  min-width: 30px;
  flex-grow: 0;
}

.nextxs-list .legend-bar .legend-item.user-icon{
  width: 50px;
  min-width: 50px;
  flex-grow: 0;
}

.nextxs-list .legend-bar .legend-item.centered {
  text-align: center;
}

.nextxs-list .legend-bar .legend-item.sort {
  cursor: pointer;
  display: flex;
}

.nextxs-list .legend-bar .legend-item.sort .sort-arrow {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 18px;
  margin: -3px 0 0 -3px;
  transition: 0.2s;
  width: 30px;
}

.nextxs-list .legend-bar .legend-item.sort .sort-arrow.up {
  background-image: url(./assets/svgs/sort_arrow_up_green.svg);
}

.nextxs-list .legend-bar .legend-item.sort .sort-arrow.down {
  background-image: url(./assets/svgs/sort_arrow_down_green.svg);
}

.nextxs-list .legend-bar .legend-item.sort:hover {
  color: #0ade0a;
  opacity: 1;
  transition: 0.2s;
}

.page-inner {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 64px);
  width: 100%;
  justify-content: flex-start;
  position: absolute;
  color: #fff;
  margin: 0 auto;
  padding: 62px 0 0 0;
}

.page-inner .actions {
  display: flex;
}

.page-inner .actions .search-bar {
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  padding: 6px 9px 5px 6px;
  margin: 25px 0 25px 0;
  width: 100%;
}

.page-inner .actions .search-bar .icon {
  background-image: url(./assets/svgs/search-dark.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  margin: 2px 4px 0 0;
  transition: 0.2s;
  width: 21px;
}

.page-inner .actions .search-bar input {
  border: none;
  font-size: 14px;
  padding: 0;
  margin: auto 0;
  width: 100%;
}

.page-inner .actions .button {
  height: -moz-min-content;
  height: -webkit-min-content;
  height: min-content;
  margin: 25px 0 0 25px;
  min-width: -moz-max-content;
  min-width: -webkit-max-content;
  min-width: max-content;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
