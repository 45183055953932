.fill {
  height: 69px;
  width: 100%;
  background-color: #121212;
}

nav {
  display: flex;
  justify-content: space-between;
  background-color: #181818;
  color: #fff;
  display: flex;
  min-width: 500px;
  padding: 10px 26px;
  position: fixed;
  width: calc(100% - 52px);
  z-index: 20;
}

nav .logo {
  background-image: url("../../assets/images/xs_alert_logo_2.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 42px;
  margin: auto 10px auto 0;
  width: 84px;
}

.MuiAppBar-root .logo {
  background-image: url("../../assets/images/xs_alert_logo_2.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 42px;
  margin: auto 10px auto 0;
  width: 84px;
}

nav .items {
  display: flex;
  position: relative;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}

nav .items a {
  border-bottom: 1px solid #121212;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  margin: auto 13px auto 28px;
  padding: 6px 0 3px 0;
  text-transform: uppercase;
}

nav .items.center button {
  font-size: 14px !important;
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

nav .items.center button:hover {
  color: rgb(10, 222, 10)
}

nav .items.center {
  display: flex;
  align-self: center;
  justify-self: center;
  align-items: center;
}

nav .items.center .choice {
  margin: 5px;
}

nav .items.left {
  margin: auto 0;
  text-align: left;
}

nav .items.left .item {
  border-bottom: 1px solid #DE0A0A;
}

nav .items.right .item.account {
  display: flex;
  margin: auto 0 auto 13px;
}

nav .items.left a:hover {
  border-bottom: 0.5px solid #0ad60a;
  transition: 0.5s;
}

nav .items.right {
  /* margin: auto 0 auto auto; */
  text-align: right;
}

nav .items.right .item .profile-circle {
  background-color: #282828;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  margin: auto 0;
  position: relative;
  width: 40px;
}

nav .items.right .item:hover .profile-circle {
  background-color: #383838;
  transition: 0.2s;
}

nav .items.right .item.add-user .profile-circle .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

nav .items.right .item.account .profile-icon {
  background-image: url("../../assets/svgs/person.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 28px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
}

nav .items.right .item.account .user-name {
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  margin: auto 0;
  min-width: -moz-max-content;
  min-width: -webkit-max-content;
  min-width: max-content;
  padding: 6px 0 6px 10px;
  text-transform: uppercase;
}

nav .drop-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: none;
  position: absolute;
  right: -1px;
  text-align: left;
  top: 45px;
  z-index: 99;
}

nav .drop-box.open {
  display: block;
}

nav .drop-box .info {
  background-color: #282828;
  padding: 13px;
}

nav .drop-box .name,
nav .drop-box .mail {
  color: #fff;
  font-size: 14px;
}

nav .drop-box .mail {
  font-size: 12px;
  margin: 2px 0 0 0;
  opacity: 0.5;
}

nav .drop-box .organization {
  background-color: #242526;
  border-top: 1px solid black;
  cursor: pointer;
  display: flex;
  padding: 13px;
}

nav .drop-box .organisation:hover {
  background-color: #191a1b;
}

nav .drop-box .organisation.active {
  color: #0ade0a;
}

nav .drop-box .organisation p {
  font-size: 14px;
  margin: auto 6px auto 9px;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}

nav .drop-box .organisation .logo-img {
  align-items: center;
  background-color: #181818;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  min-height: 40px;
  min-width: 40px;
}

/* .organization:hover {
  background-color: #191a1b !important;
} */

.organization .logo-img {
  align-self: center;
  background-color: #181818;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.organization .name {
  text-align: center;
  padding: 5px;
  font-size: 12px;
}

nav .drop-box .sign-out {
  text-align: left;
  text-transform: unset;
  margin: unset;
}

nav .drop-box .sign-out p {
  background-color: #282828;
  border-top: 1px solid black;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  padding: 13px;
}

nav .drop-box .sign-out p:hover {
  background-color: #181818;
}
