.status-dock {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 50px;
    width: 100%;
    z-index: 1;
}

.status-dock .container{
    background-color: rgb(25, 26, 27);
    display: flex;
    height: 50px;
    margin: 5px 25∂px 5px 25px;
    justify-content: space-around;
    width: 250px;
}

.status-dock .container .mode{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 40px;
}

.status-dock .container .mode .icon{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 25px;
    width: 25px;
}

.status-dock .container .tech-state {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 35px;
}
  
.status-dock .container .tech-state .active{
    border-radius: 8px;
    height: 8px;
    width: 8px;
}
  
.status-dock .container .tech-state .active.true{
    background-color: #0ade0a;
}
  
.status-dock .container .tech-state .active.false{
    background-color: #DE0A0A;
}
  
.status-dock .container .tech-state .icon{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 20px;
    opacity: 0.9;
    width: 25px;
}